import axios, { AxiosRequestConfig, ResponseType } from "axios";

const get = async <TResponseType>(url: string, requestHeaders?: IHeaders, params?: any, responseType?: ResponseType): Promise<TResponseType> => {
    try {
        const headers: { [id: string]: string } = {}
        if (requestHeaders?.accessToken) { headers["Authorization"] = `Bearer ${requestHeaders.accessToken}` }
        if (requestHeaders?.apiKey) { headers["Api-Key"] = requestHeaders.apiKey; }
        if (requestHeaders?.functionsKey) { headers["x-functions-key"] = requestHeaders.functionsKey };

        const config: AxiosRequestConfig = {
            headers,
            params: params,
            responseType: responseType || 'json',
        };

        const response = await axios.get<TResponseType>(url, config);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

const httpDelete = async <TResponseType>(url: string, requestHeaders?: IHeaders, params?: any, responseType?: ResponseType): Promise<TResponseType> => {
    try {

        const headers: { [id: string]: string } = {}
        if (requestHeaders?.accessToken) { headers["Authorization"] = `Bearer ${requestHeaders.accessToken}` }
        if (requestHeaders?.apiKey) { headers["Api-Key"] = requestHeaders.apiKey; }
        if (requestHeaders?.functionsKey) { headers["x-functions-key"] = requestHeaders.functionsKey };

        const config: AxiosRequestConfig = {
            headers,
            params: params,
            responseType: responseType || 'json',
        };

        const response = await axios.delete<TResponseType>(url, config);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

const post = async <TResponseType>(url: string, body: any, requestHeaders?: IHeaders, params?: any): Promise<TResponseType> => {
    try {
        const headers: { [id: string]: string } = {}
        if (requestHeaders?.accessToken) { headers["Authorization"] = `Bearer ${requestHeaders.accessToken}` }
        if (requestHeaders?.apiKey) { headers["Api-Key"] = requestHeaders.apiKey; }
        if (requestHeaders?.functionsKey) { headers["x-functions-key"] = requestHeaders.functionsKey };

        const config: AxiosRequestConfig = {
            headers,
            params: params,
        };

        const response = await axios.post<TResponseType>(url, body, config);

        return response.data;
    } catch (error: any) {
        throw error;
    }
}

const getImage = async (url: string, requestHeaders?: IHeaders, params?: any): Promise<IImageResponse> => {
    try {
        const headers: { [id: string]: string } = {}
        if (requestHeaders?.accessToken) { headers["Authorization"] = `Bearer ${requestHeaders.accessToken}` }
        if (requestHeaders?.apiKey) { headers["Api-Key"] = requestHeaders.apiKey; }
        if (requestHeaders?.functionsKey) { headers["x-functions-key"] = requestHeaders.functionsKey };

        const config: AxiosRequestConfig = {
            headers: headers,
            responseType: 'blob',
            params: params,
        };

        const response = await axios.get<Blob>(url, config);
        const imageUrl = URL.createObjectURL(response.data);
        const type = response.data.type;

        return { type, url: imageUrl };
    } catch (error: any) {
        if (error.response.status === 404) {
            return { type: "", url: "" }
        }
        throw error;
    }
}

const ApiRoot = {
    get,
    getImage,
    post,
    delete: httpDelete,
}

export interface IHeaders {
    accessToken?: string;
    functionsKey?: string;
    apiKey?: string;
}

export interface IImageResponse {
    type: string;
    url: string;
}

export default ApiRoot;