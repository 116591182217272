import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import OutageForms from "../../components/operate/OutageForms";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import availabilityApi from "../../shared/api/AvailabilityApi";
import { Dictionary } from "@reduxjs/toolkit";
import { IEngine } from "../../shared/types/operate/IEngine";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { EditCalendar, EventAvailable } from "@mui/icons-material";
import { OperatePermissions } from "../../shared/types/shared/auth/permissionsTypes";

const DeclareOutagePage: React.FC<IDeclareOutagePage> = () => {
    const [loadedAssets, setLoadedAssets] = useState<boolean>(false);
    const [loadedGenerators, setLoadedGenerators] = useState<boolean>(false);
    const [siteIdToName, setSiteIdToName] = React.useState<Dictionary<string>>({})
    const [siteIdToCapacity, setSiteIdToCapacity] = React.useState<Dictionary<number>>({})
    const [siteIDToGenerators, setSiteIDToGenerators] = React.useState<Map<string, Array<IEngine>>>(new Map<string, Array<IEngine>>())
    const [siteIDToType, setSiteIDToType] = useState<Dictionary<string>>({})
    const { data: assets } = useGetLiveAssetsQuery();

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            var dictType: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
                const genType = item.GenerationType
                const formattedGenType = genType.charAt(0).toUpperCase() + genType.slice(1).toLowerCase()
                dictType[item.SiteID] = formattedGenType
            });
            setSiteIdToName(dictName);
            setSiteIDToType(dictType);
            setLoadedAssets(true);
        }
    }

    useEffect(() => {
        if (assets !== undefined && siteIDToGenerators.size > 0) {
            var dictCapacity: Dictionary<number> = {}
            assets?.forEach((item) => {
                if (item.CapabilityKW === null) {
                    const engines = siteIDToGenerators.get(item.SiteID)
                    let siteCapacity = 0
                    if (engines !== undefined) {
                        engines.forEach(engine => {
                            siteCapacity += engine.grossOutput
                        })
                    }
                    dictCapacity[item.SiteID] = siteCapacity / 1000
                }
                else {
                    dictCapacity[item.SiteID] = item.CapabilityKW / 1000
                }
                
            });
            setSiteIdToCapacity(dictCapacity);
        }
    }, [assets, siteIDToGenerators])

    const fetchEngineData = () => {
        availabilityApi.getEngines()
            .then((engines) => {
                let dict = new Map<string, Array<IEngine>>();
                engines.forEach((engine) => {
                    var generators = dict.get(engine.siteID);
                    if (generators !== undefined) {
                        generators.push(engine);
                        dict.set(engine.siteID, generators);
                    }
                    else {
                        dict.set(engine.siteID, new Array<IEngine>(engine));
                    }
                })
                setSiteIDToGenerators(dict);
                setLoadedGenerators(true);
            })
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    useEffect(() => {
        fetchEngineData()
    }, [])

    return (
        <PageWrapper title="Declare New Outage" loaded={loadedAssets && loadedGenerators}>
            <OutageForms type={"Submit"} siteIdToName={siteIdToName} siteIdToCapacity={siteIdToCapacity} siteIDToGenerators={siteIDToGenerators} siteIDToType={siteIDToType} />
        </PageWrapper>
    );
}

export interface IDeclareOutagePage {

}

const DeclareOutagePageConfig: IPortalRouteOptions = {
    relativeUrl: "availability",
    page: <DeclareOutagePage />,
    navDisplay: {
        title: "Availability",
        icon: <EventAvailable />,
        nestedTitle: "Declare New Outage",
        nestedIcon: <EditCalendar />,
    },
    requiredPermissions: { Operate: OperatePermissions.Admin },
}

export default DeclareOutagePageConfig;
