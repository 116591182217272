import { IDispatchResponse, SiteManualStatus } from "../types/asset/status-hive";

const manualStartUrl = "https://pi-application.conradcloud.net/manualstart/GasSiteManualStart"

function makeRequest(method: string, url: string) {
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open(method, url);

        if (method.toLowerCase() === "post") {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }

        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.send();
    });
}

const sendManualDispatchRequest = async (siteName: string, webID: string, newStatus: SiteManualStatus) => {
    let responseStatus = true

    try {
        const path = "\\\\PI-AF\\iON\\Conrad\\" + siteName + "|Site Manual Control";
        let payloadValue = 0;
        if (newStatus === SiteManualStatus.On) { payloadValue = 2 }
        else if (newStatus === SiteManualStatus.Off) { payloadValue = 4 }
        const queryString = "?WebID=" + webID + "&ManualAction=" + payloadValue + "&Site=" + path;
        const fullUrl = manualStartUrl + queryString;
        await makeRequest("GET", fullUrl);
    }
    catch {
        responseStatus = false
    }
    return {
        siteName: siteName,
        status: responseStatus
    } as IDispatchResponse
}


const dispatchApi = {
    sendManualDispatchRequest
}

export default dispatchApi;