import { IAllPermissionsConfig } from "../../../api/PermissionsApi";
import { userHasAllRequiredPermissions, userHasAnyRequiredPermissions } from "../../../auth/permissions/permissionsHelpers";
import { IImage } from "../files";
import { AllPermissions, PermissionsArea, PermissionsType, SystemAdministrationPermissions } from "./permissionsTypes";
import { IUserDataWithAvatar, UserPermissionCollection, IUserRole } from "./userDataTypes";

export class PortalUser {
    public readonly userId: string;
    public readonly userName: string;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly displayName: string;
    public readonly avatar?: IImage;
    public readonly jobTitle: string;
    private _userGroups: string[];
    private _permissions: UserPermissions;

    constructor(userData: IUserDataWithAvatar, roles: IUserRole[], allPermissions: IAllPermissionsConfig) {
        this.userId = userData.id;
        this.userName = userData.userPrincipalName;
        this.firstName = userData.givenName;
        this.lastName = userData.surname;
        this.displayName = userData.displayName;
        this.avatar = userData.avatar;
        this.jobTitle = userData.jobTitle;
        this._userGroups = this.getUserGroups(roles);
        this._permissions = new UserPermissions(this._userGroups, this.userName, allPermissions);
    }

    /**Returns whether this user is a member of the provided group.*/
    public readonly isInGroup = (group: string): boolean => {
        return this._userGroups.findIndex(g => g === group) > -1;
    }

    /**
    * Determine if this user has the specified permissions.
    *
    * @param permissions - The permissions to check
    * @param all - If `true`, check if **all** specified permissions are present. If `false`, check if **any** specified permissions are present.
    * @returns Whether the specified permissions are a match
    */
    public readonly hasPermissions = (permissions?: Partial<UserPermissionCollection>, all: boolean = true): boolean => {
        if (!permissions) { return true; }

        return this._permissions.hasPermissions(permissions, all);
    }

    private readonly getUserGroups = (roles?: IUserRole[]) => {
        const userGroups: string[] = [];

        roles?.forEach((role) => {
            const adGroup = role.principalDisplayName;
            if (!userGroups.find(g => g === adGroup)) {
                userGroups.push(adGroup);
            }
        });


        return userGroups;
    }
}

export class UserPermissions implements UserPermissionCollection {
    public readonly Optimise: PermissionsType;
    public readonly Operate: PermissionsType;
    public readonly Analytics: PermissionsType;
    public readonly Dispatch: PermissionsType;
    public readonly Asset: PermissionsType;
    public readonly Data: PermissionsType;
    public readonly SystemAdmin: PermissionsType;
    public readonly Commercial: PermissionsType;

    public constructor(groups: string[], user: string, allPermissions: IAllPermissionsConfig) {
        const permissions = this.buildUserPermissions(groups, user, allPermissions);
        this.Optimise = permissions.Optimise;
        this.Operate = permissions.Operate;
        this.Analytics = permissions.Analytics;
        this.Dispatch = permissions.Dispatch;
        this.Asset = permissions.Asset;
        this.Data = permissions.Data;
        this.SystemAdmin = permissions.SystemAdmin;
        this.Commercial = permissions.Commercial;
    }

    /**
     * Determine if this permissions set has the specified permissions.
     *
     * @param permissions - The permissions to check
     * @param all - If `true`, check if **all** specified permissions are present. If `false`, check if **any** specified permissions are present.
     * @returns Whether the specified permissions are a match
     */
    public readonly hasPermissions = (permissions: Partial<UserPermissionCollection>, all: boolean = true): boolean => {
        const checkerMethod = all ? userHasAllRequiredPermissions : userHasAnyRequiredPermissions;

        const matches: boolean[] = [];

        // Default match value is the value of all, i.e 
        // if it should match all of them and no value was provided, treat it as true
        // if it only needs to match one and no value was provided, treat it as false

        Object.keys(AllPermissions).forEach((area) => {
            const permissionsArea = area as PermissionsArea;
            const areaPermissions = permissions[permissionsArea];
            matches.push(areaPermissions ? checkerMethod(areaPermissions, this[permissionsArea]) : all);
        });

        if (all) {
            return !matches.includes(false);
        } else {
            return matches.includes(true);
        }
    }

    private readonly buildUserPermissions = (groups: string[], user: string, allPermissions: IAllPermissionsConfig): UserPermissionCollection => {
        if (process.env.NODE_ENV === "development") {
            console.log("IN DEVELOPMENT MODE: USER GRANTED ALL PERMISSIONS");
            return this.createNewObject(true);
        }

        let builtPermissions = this.createNewObject(false);
        console.log(user);

        const privilegedPermissions = allPermissions.users.find((p) => p.id.toLowerCase() === user.toLowerCase());
        builtPermissions = this.addPermissions(builtPermissions, privilegedPermissions?.permissions);

        groups.forEach(group => {
            const userGroupPermissions = allPermissions.groups.find((p) => p.id.toLowerCase() === group.toLowerCase());
            builtPermissions = this.addPermissions(builtPermissions, userGroupPermissions?.permissions);
        });

        console.log(builtPermissions);

        return builtPermissions;
    }

    private createNewObject(all: boolean): UserPermissionCollection {
        const obj: Partial<UserPermissionCollection> = {};

        Object.keys(AllPermissions).forEach((key) => {
            obj[key as PermissionsArea] = all ? ~(1 << 31) : 0;
        });

        return obj as UserPermissionCollection;
    }

    private addPermissions(existingPermissions: UserPermissionCollection, newPermissions?: Partial<UserPermissionCollection>) {
        if (!newPermissions) { return existingPermissions; }

        // userPermissions |= Value; // add permission
        // userPermissions ^= Value; // toggle permission
        // userPermissions &= (~Value); // remove permission

        Object.keys(AllPermissions).forEach((area) => {
            const permissionsArea = area as PermissionsArea;
            const newAreaPermissions = newPermissions[permissionsArea];
            if (!newAreaPermissions) { return; }

            existingPermissions[permissionsArea] |= newAreaPermissions;
        });

        return existingPermissions;
    }
}
