import { AssetType } from '../../shared/types/ISiteStatus';
import { StatusHiveSiteData } from '../../shared/types/asset/status-hive';
import { useEffect, useState } from 'react';
import { HexIndicatorBar } from './HexIndicatorBar';

export const HiveIndicatorBar: React.FC<IHiveIndicatorBar> = ({ status, colour }) => {
    const [value, setValue] = useState<number>(0)
    const [maxValue, setMaxValue] = useState<number>(0)
    const [label, setLabel] = useState<string>("")


    useEffect(() => {
        if (status.underOffer) {
            setValue(status.boaAmount || 0)
            setMaxValue(status.siteCapacity || 0)
            setLabel("Offer MW v. Capacity MW")
        }
        else if (status.assetType !== AssetType.Battery || status.activePowerExport > 0.1) {
            setValue(status.activePowerExport || 0)
            setMaxValue(status.availableExport || 0) // Check if should be available, PN or dispatch plan
            setLabel("Export MW v. Declared MW")
        }
        else {
            setValue(Math.abs(status.activePowerImport || 0))
            setMaxValue(Math.abs(status.availableImport || 0))
            setLabel("Import MW v. Declared MW")
        }
    }, [status])

    return (
        <HexIndicatorBar
            x={0}
            y={0}
            width={0.75}
            height={0.05}
            fill={colour}
            value={value}
            maxValue={maxValue}
            fillHorizontally={true}
            valueTooltipLabel={label}
            unit={"MW"}
        />
    )
}

export interface IHiveIndicatorBar {
    status: StatusHiveSiteData
    colour: string
}