import { CubicCoords } from '../../shared/types/shared/hex-grid';
import { useColourModeContext } from '../../components/ColourMode';
import { common } from '@mui/material/colors';
import { HexMultiLineText } from './HexMultiLineText';
import { HexButton } from './HexButton';

export const CancelDispatchButton: React.FC<ICancelDispatchButton> = ({ location, onClick }) => {
    const { isDarkMode } = useColourModeContext();

    return (
        <HexButton location={location} cellStyle={{ fill: "none" }} stroke={isDarkMode ? common.white : common.black} strokeWidth={"0.01em"} style={{ cursor: "pointer" }} onClick={onClick}>
            <HexMultiLineText text={"Cancel all manual dispatches?"} backgroundColor={"#0000FF"} colour={isDarkMode ? common.white : common.black} y={-0.5} fontSize={0.08} fontWeight={800} />
        </HexButton>
    );
}

export interface ICancelDispatchButton {
    location: CubicCoords,
    onClick: () => void
}