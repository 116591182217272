import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getApiAccessToken } from "../auth/helpers";
import { IDetailedGasAsset } from "../types/asset/IDetailedGasAsset";
import { ISiteEngineResponse } from "../types/ISiteEngineResponse";
import { IAssetInformation, ISiteLists } from "../types/IAssetInformation";
import { ISiteStatus } from "../types/ISiteStatus";

export const assetApi = createApi({
    reducerPath: 'assetApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    tagTypes: ["DetailedGasAssets"],
    endpoints: (builder) => ({
        getAssets: builder.query<IAssetInformation[], void>({ 
            query: () => ({
                url: '/site/list',                
                method: 'POST',
                body: {
                    "Attributes": [
                        "allSites",
                        "PAS",
                        "BMsites",
                        "nonBMsites",
                        "LiveSites",
                        "gasDailyMetered",
                        "gasNonDailyMetered"
                    ],
                    "ForceRefresh": false
                }
            }),
            keepUnusedDataFor: 10 * 60,     // 10 minutes
            transformResponse: (rawResult: ISiteLists): IAssetInformation[] => {
                const sites: IAssetInformation[] = rawResult.SiteLists.allSites;
                const otherLiveSites = rawResult.SiteLists.LiveSites.filter(liveSite => !rawResult.SiteLists.allSites.find(site => site.SiteID === liveSite.SiteID));
                return [...sites, ...otherLiveSites];
            },      
        }),
        getLiveAssets: builder.query<IAssetInformation[], void>({
            query: () => ({
                url: '/site/list',
                method: 'POST',
                body: {
                    "Attributes": [
                        "allSites",
                        "LiveSites"
                    ],
                    "ForceRefresh": false
                }
            }),
            keepUnusedDataFor: 10 * 60,
            transformResponse: (rawResult: ISiteLists): IAssetInformation[] => {
                const liveSites = rawResult.SiteLists.LiveSites

                // LARRT isn't fully live yet but was asked to be added to the availability portal
                rawResult.SiteLists.allSites.forEach(site => {
                    if (site.SiteID === "LARRT") {
                        liveSites.push(site)
                    }
                })
                return liveSites
            },
        }),
        getAssetStatus: builder.query<ISiteStatus[], void>({
            query: () => '/site/status', 
        }),
        getEngineStatus: builder.query<ISiteEngineResponse[], void>({
            query: () => '/site/engineStatus',
        }),
        getDetailedGasAssets: builder.query<IDetailedGasAsset[], void>({
            query: () => '/site/detailed-gas-site',
            providesTags: ["DetailedGasAssets"],
        }),
        updateDetailedGasAsset: builder.mutation<void, IDetailedGasAsset>({
            query: (payload) => ({
                url: 'site/detailed-gas-site',
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ["DetailedGasAssets"]
        }),
        addDetailedGasAsset: builder.mutation<void, IDetailedGasAsset>({
            query: (payload) => ({
                url: 'site/detailed-gas-site',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ["DetailedGasAssets"]
        }),
        deleteDetailedGasAsset: builder.mutation<void, IDetailedGasAsset>({
            query: (payload) => ({
                url: 'site/detailed-gas-site',
                method: 'DELETE',
                body: payload
            }),
            invalidatesTags: ["DetailedGasAssets"]
        }),

    }),
})
export const { useGetAssetsQuery, useGetLiveAssetsQuery, useGetAssetStatusQuery, useGetEngineStatusQuery, useGetDetailedGasAssetsQuery, useAddDetailedGasAssetMutation, useUpdateDetailedGasAssetMutation, useDeleteDetailedGasAssetMutation } = assetApi

